import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import {
  BaseResponse,
  BaseResponsePagination,
} from "../data/bases/base-response";
import {
  Restriction,
  RestrictionData,
  RestrictionFilter,
} from "../data/restriction";
import { setBaseFilter } from "../utils/http/base-http-filter";

@Injectable()
export class RestrictionService extends RestrictionData {
  private baseUrl = environment.services.accessControl.baseUrl;

  constructor(private http: HttpClient) {
    super();
  }

  list(
    filter: RestrictionFilter
  ): Observable<BaseResponse<Restriction[], BaseResponsePagination>> {
    var params = setBaseFilter(filter);

    if (filter.id) params = params.set("id", filter.id);

    if (filter.name) params = params.set("name", filter.name);

    if (filter.isActive) params = params.set("isActive", filter.isActive);

    return this.http.get<BaseResponse<Restriction[], BaseResponsePagination>>(
      `${this.baseUrl}/api/v1/restriction`,
      { params }
    );
  }

  update(restriction: Restriction) {
    return this.http.put(
      `${this.baseUrl}/api/v1/restriction/${restriction.id}`,
      restriction
    );
  }

  delete(id) {
    return this.http.delete(`${this.baseUrl}/api/v1/restriction/${id}`, id);
  }

  insert(restriction: Restriction) {
    return this.http.post(`${this.baseUrl}/api/v1/restriction/`, restriction);
  }
}
