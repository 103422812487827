import { Observable } from "rxjs";
import { BaseResponse, BaseResponsePagination } from "./bases/base-response";


export interface AssetReductionCount {
  id?:number;
  name?:string;

}

export interface AssetReductionFilter{
  id?:number;

}

export abstract class AssetReductionData {
    abstract list(filter:AssetReductionCount, pagination:any): Observable<BaseResponse<AssetReductionCount[],BaseResponsePagination>>
    abstract insert(assetReductionCount:AssetReductionCount);
    abstract get(id);
    abstract delete(id);
    abstract update(assetReductionCount:AssetReductionCount)

}