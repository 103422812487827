import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbThemeModule,
  NbCardModule,
  NbInputModule,
  NbCheckboxModule,
} from "@nebular/theme";
import { NbEvaIconsModule } from "@nebular/eva-icons";
import { NbSecurityModule } from "@nebular/security";

import {
  FooterComponent,
  HeaderComponent,
  SearchInputComponent,
  TinyMCEComponent,
  DialogComponent,
  BankInformationsSelectComponent,
  EmailSelectComponent,
  ColumnSelectorSelectComponent,
} from "./components";
import {
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
} from "./pipes";
import {
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,

} from "./layouts";
import { DEFAULT_THEME } from "./styles/theme.default";
import { COSMIC_THEME } from "./styles/theme.cosmic";
import { CORPORATE_THEME } from "./styles/theme.corporate";
import { DARK_THEME } from "./styles/theme.dark";
import { PagerComponent } from "./components/pager/pager.component";
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { UploaderComponent } from "./components/uploader/uploader.component";
import { FormsModule } from "@angular/forms";
import { ActionsDropdownComponent } from "./components/access/actions-dropdown/actions-dropdowns.component";
import { AccessLicenseComponent } from "./components/access/license/access-license.component";
import { Ng2SmartTableModule } from "ng2-smart-table";
import { ContactSelectComponent } from "./components/dialogs/contact-select/contact-select.component";
import { ActionCheckboxComponent } from "./components/dialogs/column-selector-select/action-checkbox/action-checkbox.component";
import { ModalTaxComponent } from './components/dialogs/modal-tax/modal-tax.component';

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbCardModule,
  FormsModule,
  NbInputModule,
  NbSelectModule,
  Ng2SmartTableModule,
  NbCheckboxModule,
];
const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  SearchInputComponent,
  TinyMCEComponent,
  DialogComponent,
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
  PagerComponent,
  SpinnerComponent,
  UploaderComponent,
  EmailSelectComponent,
  BankInformationsSelectComponent,
  ActionsDropdownComponent,
  AccessLicenseComponent,
  ModalTaxComponent,
  ContactSelectComponent,
  ColumnSelectorSelectComponent,
  ActionCheckboxComponent,
];
const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
];

@NgModule({
  imports: [CommonModule, ...NB_MODULES],
  exports: [CommonModule, ...PIPES, ...COMPONENTS],
  declarations: [
    ...COMPONENTS,
    ...PIPES,
    SpinnerComponent,
    ContactSelectComponent,
  ],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: "default",
          },
          [DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME]
        ).providers,
      ],
    };
  }
}
