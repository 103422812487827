import { HttpParams } from "@angular/common/http";

import { BaseFilter } from "../../data/bases/base-filter";

export function setBaseFilter(filter: BaseFilter): HttpParams {
  var params = new HttpParams();

  if (filter.currentPage)
    params = params.set("currentPage", filter.currentPage);

  if (filter.orderBy) params = params.set("orderBy", filter.orderBy);

  if (filter.pageSize) params = params.set("pageSize", filter.pageSize);

  if (filter.sortBy) params = params.set("sortBy", filter.sortBy);

  return params;
}
