import { Observable } from "rxjs";
import { BaseResponse, BaseResponsePagination } from "./bases/base-response";

export interface Tax{
    id?:number;
    acronym?:string;
    rfb_code?:string;
    description?:string;
    start_date?:string;
    end_date?:string;
    tax_type?:string;
    collection_code?: string;
    tax_rate?: string;
    incidence?: string;
    due_date?: string;
    due_day?: number;
    frequency?: string;
    payment_method?: string;
    payment_guide?: string,
    has_tiered_tax_rates?: boolean,
    observation?: string,
    price_ranges?:Prices[] 

}

export interface TaxFilter{
    id?:number;
    acronym?:string;
    rfb_code?:string;
    description?:string;
    start_date?:Date;
    end_date?:Date;
    tax_type?:string;
    collection_code?: string;
    tax_rate_min?: string;
    tax_rate_max?: string;
    incidence?: string;
    due_date?: string;
    due_day?: number;
    frequency?: string;
    payment_method?: string;
}

export interface Prices{
//    id?: number,
   start_of_validity?: string | Date,
   end_of_validity?: string | Date,
   start_price_range?: string,
   end_price_range?: string,
   tax_rate?: string,
   sequence?: number,
   deductible_amount?: string
}

export abstract class TaxData {
abstract list(filter:TaxFilter, pagination:any): Observable<BaseResponse<Tax[],BaseResponsePagination>>
abstract delete(id);
abstract get(id);
abstract getTax();
abstract insert(tax:Tax);
abstract update(costCenter:Tax)
}
