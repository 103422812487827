import { Observable } from "rxjs";
import { BaseResponse, BaseResponsePagination } from "./bases/base-response";


export interface ProvisionCount {
  id?:number;
  name?:string;

}

export interface ProvisionCountFilter{
  id?:number;
  name?: string;

}

export abstract class ProvisionCountData {
    abstract list(filter:ProvisionCount, pagination:any): Observable<BaseResponse<ProvisionCount[],BaseResponsePagination>>
    abstract insert(provisionCount:ProvisionCount);
    abstract get(id);
    abstract delete(id);
    abstract update(provisionCount:ProvisionCount)

}