import { Observable } from "rxjs";

import { BaseFilter } from "./bases/base-filter";
import { BaseModel, License } from "./bases/base-model";
import { BaseResponse, BaseResponsePagination } from "./bases/base-response";

export interface JuridicType extends BaseModel {
  id?: string;
  name?: string;
}

export interface JuridicTypeFilter extends JuridicType, BaseFilter {}

export abstract class JuridicTypeData {
  abstract list(
    filter: JuridicTypeFilter
  ): Observable<BaseResponse<JuridicType[], BaseResponsePagination>>;
}
