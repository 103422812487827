import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ngx-modal-tax',
  templateUrl: './modal-tax.component.html',
  styleUrls: ['./modal-tax.component.scss']
})
export class ModalTaxComponent implements OnInit {
  @Input() quantasFaixas: number = 1; // Número de faixas que o usuário quer adicionar
  @Output() confirmar = new EventEmitter<any>(); // Emite os dados para o componente pai

  // Modelo para armazenar as faixas de valores
  faixa: any[] = [];

  ngOnInit() {
    // Inicializa o array de faixas de acordo com o número de faixas que o usuário quer adicionar
    this.faixa = new Array(this.quantasFaixas).fill({
      start_of_validity: '',
      end_of_validity: '',
      start_price_range: '',
      end_price_range: '',
      tax_rate: '',
      deductible_amount: ''
    });
  }

  // Método para confirmar a adição das faixas
  confirmarFaixas() {
    this.confirmar.emit(this.faixa); // Emite os dados para o componente pai
  }

  // Método para cancelar e fechar o modal
  cancelar() {
    this.faixa = []; // Limpa os dados
  }
}
