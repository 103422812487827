import { Observable } from "rxjs";

import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "../../../environments/environment";
import { Account, AccountData, AccountFilter } from "../data/account";
import { Bank } from "../data/bank";
import { BaseResponse } from "../data/bases/base-response";
import { User } from "../data/user";

@Injectable()
export class AccountService extends AccountData {
  private baseUrl = environment.services.financial.baseUrl;

  constructor(private http: HttpClient) {
    super();
  }

  insert(account: Account<number>) {
    return this.http.post(`${this.baseUrl}/api/account/`, account);
  }

  update(account: Account<number>) {
    var result = this.http.put(
      `${this.baseUrl}/api/account/?id=${account.id}`,
      account
    );
    return result;
  }

  delete(id) {
    return this.http.delete(`${this.baseUrl}/api/account/${id}/`, id);
  }

  get(id): Observable<BaseResponse<Account<Bank>, any>> {
    var result = this.http.get<BaseResponse<Account<Bank>, any>>(
      `${this.baseUrl}/api/account/?id=${id}`
    );
    return result;
  }

  list(
    filter: AccountFilter<number>,
    pagination: any
  ): Observable<BaseResponse<Account<Bank>[], any>> {
    var params = new HttpParams();
    params = params.set("page", pagination.currentPage);
    params = params.set("page_size", pagination.perPage);

    if (filter.agency) params = params.set("agency", filter.agency);
    if (filter.bank_account)
      params = params.set("bank_account", filter.bank_account);
    if (filter.project_number)
      params = params.set("project_number", filter.project_number);
    if (filter.bank) params = params.set("bank", filter.bank);
    if (filter.user?.id) params = params.set("user", filter.user.id);

    return this.http.get<BaseResponse<Account<Bank>[], any>>(
      `${this.baseUrl}/api/account/`,
      { params }
    );
  }

  listByUser(
    userId: string,
    pagination: any,
    filter: Account<Bank>
  ): Observable<BaseResponse<Account<Bank>[], any>> {
    var params = new HttpParams();

    if (userId) {
      params = params.set("user", userId);
    }

    if (filter.agency) params = params.set("agency", filter.agency);

    if (filter.bank_account)
      params = params.set("bank_account", filter.bank_account);

    if (pagination) {
      params = params.set("page", pagination.currentPage);
      params = params.set("page_size", pagination.perPage);
    }

    return this.http.get<BaseResponse<Account<Bank>[], any>>(
      `${this.baseUrl}/api/account/`,
      { params }
    );
  }
}
