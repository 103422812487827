import { Observable } from "rxjs";

import { BaseFilter } from "./bases/base-filter";
import { BaseResponse, BaseResponsePagination } from "./bases/base-response";
import { AddressRequestDto, AddressResponseDto } from "./dto/address.dto";

export interface JuridicAddressFilter extends BaseFilter {
  id?: string;
}

export abstract class JuridicAddressData {
  abstract list(
    juridicId: string,
    filter: JuridicAddressFilter
  ): Observable<BaseResponse<AddressResponseDto[], BaseResponsePagination>>;
  abstract getById(
    id: string
  ): Observable<BaseResponse<AddressResponseDto, BaseResponsePagination>>;
  abstract update(id: string, request: AddressRequestDto): Observable<any>;
  abstract insert(
    juridicId: string,
    request: AddressRequestDto
  ): Observable<any>;
  abstract delete(id: string): Observable<any>;
}
