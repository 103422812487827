import { Observable } from "rxjs";

import { BaseFilter } from "./bases/base-filter";
import { BaseResponse, BaseResponsePagination } from "./bases/base-response";
import { JuridicRequestDto, JuridicResponseDto } from "./dto/juridic.dto";

export interface JuridicFilter extends BaseFilter {}

export abstract class JuridicData {
  abstract list(
    filter: JuridicFilter
  ): Observable<BaseResponse<any[], BaseResponsePagination>>;
  abstract getById(
    id: string
  ): Observable<BaseResponse<JuridicResponseDto, BaseResponsePagination>>;
  abstract update(Juridic: JuridicRequestDto);
  abstract delete(id);
  abstract insert(Juridic: JuridicRequestDto);
}
