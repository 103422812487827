import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { BaseResponse, BaseResponsePagination } from "../data/bases/base-response";
import { NatureCount, NatureCountData } from "../data/nature-count";

@Injectable()
export class NatureCountService extends NatureCountData {
    private baseUrl = environment.services.financial.baseUrl;

  constructor(private http:HttpClient) { super() }

  list(filter:NatureCount, pagination:any): Observable<BaseResponse<NatureCount[],BaseResponsePagination>> {

   
    let params = new HttpParams()
    .set('page', pagination.currentPage)
    .set('page_size', pagination.pageSize);
    if(filter.id)
      params = params.set('id', filter.id)

    if(filter.name)
      params = params.set('name', filter.name)

      params = params.set('orderBy', '').set('sortedBy', '');

    return this.http.get<BaseResponse<NatureCount[],BaseResponsePagination>>(`${this.baseUrl}/api/account_nature/`, { params })
  }

  insert(natureCount:NatureCount){
    return this.http.post(`${this.baseUrl}/api/account_nature/`,natureCount)
  }

  delete(id){
    return this.http.delete(`${this.baseUrl}/api/account_nature/${id}`,id)
  }

  update(natureCount:NatureCount){
    const natureCountData = { "name": natureCount.name };
    return this.http.put(`${this.baseUrl}/api/account_nature/${natureCount.id}/`,natureCountData)
  }

  get(id) {
    var result = this.http.get(`${this.baseUrl}/api/account_nature/${id}/`,id)
    return result;
  }
}