import { Observable } from "rxjs";

import { BaseFilter } from "./bases/base-filter";
import { BaseModel } from "./bases/base-model";
import { BaseResponse, BaseResponsePagination } from "./bases/base-response";

export interface Restriction extends BaseModel {
  id?: string;
  name?: string;
}

export interface RestrictionFilter extends Restriction, BaseFilter {}

export abstract class RestrictionData {
  abstract list(
    filter: RestrictionFilter
  ): Observable<BaseResponse<Restriction[], BaseResponsePagination>>;
  abstract update(Restriction: Restriction);
  abstract delete(id);
  abstract insert(Restriction: Restriction);
}
