import { Injectable } from '@angular/core';
import { Account, AccountData, AccountFilter } from '../data/account';
import { User } from '../data/user';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseResponse, BaseResponsePagination } from '../data/bases/base-response';
import { Bank } from '../data/bank';
import { CostCenter, CostCenterData, CostCenterFilter } from '../data/cost-center';
import { toBackendDateFormat } from '../utils/common/datetime-utils';

@Injectable()
export class CostCenterService extends CostCenterData {

  private baseUrl = environment.services.financial.baseUrl;

  constructor(private http: HttpClient) { super() }

  list(filter: CostCenterFilter, pagination: any): Observable<BaseResponse<CostCenter[], BaseResponsePagination>> {
    let params = new HttpParams()
      .set('page', pagination.currentPage)
      .set('page_size', pagination.perPage);
  
    // Adiciona as datas apenas se elas estiverem definidas
    if (filter.start_date instanceof Date) {
      params = params.set('start_date', toBackendDateFormat(filter.start_date));
    }
    if (filter.end_date instanceof Date) {
      params = params.set('end_date', toBackendDateFormat(filter.end_date));
    }
  
    if (filter.abbreviation) params = params.set('abbreviation', filter.abbreviation);
    if (filter.name) params = params.set('name', filter.name);
    if (filter.situation) params = params.set('situation', filter.situation);
  
    params = params.set('orderBy', '').set('sortedBy', '');
  
    return this.http.get<BaseResponse<CostCenter[], BaseResponsePagination>>(
      `${this.baseUrl}/api/cost_center/`, 
      { params }
    );
  }

  delete(id){
    return this.http.delete(`${this.baseUrl}/api/cost_center/${id}`,id)
  }

  update(costCenter:CostCenter){
    const { id, ...costCenterData } = costCenter;
    return this.http.put(`${this.baseUrl}/api/cost_center/${costCenter.id}/`, costCenterData)
  }

  insert(costCenter:CostCenter){
    return this.http.post(`${this.baseUrl}/api/cost_center/`,costCenter)
  }

  get(id) {
    var result = this.http.get(`${this.baseUrl}/api/cost_center/${id}/`,id)
    return result;
  }
  
  
}