import { Observable } from "rxjs";
import { BaseResponse } from "./bases/base-response";
import { Bank } from "./bank";
import { User } from "./user";

export interface AccountPlanAnalitica {
    name: string;
    demonstratives: number[];
    account_structure_type: string; // Sintetica, analitica etc.
    accounting_classification: string; // Patrimonial, resultado etc.
    account_nature: string; // Devedora, credora etc.
    reduction_type: string; // Nenhum, percentual etc.
    cost_centers: Array<{
      cost_center: number;
      percentage?: string;
    }>;
    tax_incidence: number[];
    provision: number;
    payroll: boolean;
    project_account: string;
    faurg_account: boolean;
    financial_application: boolean;
    bank: string;
    agency: string;
    account: string;
    balance_update: string; // Nenhum, diário, mensal etc.
    budget_participation: boolean;
    validity_start: string; // ISO 8601
    validity_end: string; // ISO 8601
    observation: string;
    account_description: string;
    status: string; // Ativa, inativa etc.
    referential_plan: string;
    parent: number;
  }
  
  export interface AccountPlanSintetica {
    name: string;
    account_structure_type: string;
    referential_plan: string;
    parent: number;
  }
  


export abstract class AccountPlanAnaliticaData {
abstract list();
abstract insertAnalitica(accountPlanAnalitica:AccountPlanAnalitica);
}

export abstract class AccountPlanSinteticaData {
abstract insertSintetica(accountPlanSintetica:AccountPlanSintetica);
}