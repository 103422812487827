import { Observable } from "rxjs";

import { BaseFilter } from "./bases/base-filter";
import { BaseResponse, BaseResponsePagination } from "./bases/base-response";
import { PartnerRequestDto, PartnerResponseDto } from "./dto/partner.dto";

export interface JuridicPartnerFilter extends BaseFilter {
  id?: string;
}

export abstract class JuridicPartnerData {
  abstract list(
    juridicId: string,
    filter: JuridicPartnerFilter
  ): Observable<BaseResponse<PartnerResponseDto[], BaseResponsePagination>>;
  abstract getById(
    id: string
  ): Observable<BaseResponse<PartnerResponseDto, BaseResponsePagination>>;
  abstract update(id: string, request: PartnerRequestDto): Observable<any>;
  abstract insert(
    juridicId: string,
    request: PartnerRequestDto
  ): Observable<any>;
  abstract delete(id: string): Observable<any>;
}
