<ngx-spinner bdColor="rgba(247,249,252,0.8)" size="medium" color="#8f9bb3" type="ball-scale-multiple" [fullScreen]="true"></ngx-spinner>

<div class="row" style="justify-content: center;">
  <img src="assets/images/faurg-logo-horizontal.png" class="logo">
</div><br><br><br>

<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="errorMenuIsEmpty" outline="danger" role="alert">
  <ul class="alert-message-list">
    <li class="alert-message">
      Você se logou com sucesso, mas ainda não há funcionalidades disponíveis para a sua conta. Estamos trabalhando para habilitá-las o mais breve possível. 

      <br><br>Se precisar de ajuda, entre em contato com <a href="gestaodeprojetos@faurg.org.br">gestaodeprojetos@faurg.org.br</a>
      
      <br><br>Obrigado pela compreensão!
    </li>
  </ul>
</nb-alert>

<form (ngSubmit)="login()" #form="ngForm" aria-labelledby="title">

  <div class="form-control-group">
    <label class="label" for="input-email">E-mail:</label>
    <input nbInput fullWidth [(ngModel)]="user.email" #email="ngModel" name="email" id="input-email" pattern=".+@.+\..+"
      placeholder="E-mail" fieldSize="large" autofocus
      [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : 'basic'" [required]="true"
      [attr.aria-invalid]="email.invalid && email.touched ? true : null">
    <ng-container *ngIf="email.invalid && email.touched">
      <p class="caption status-danger" *ngIf="email.errors?.required">
        E-mail é obrigatório!
      </p>
      <p class="caption status-danger" *ngIf="email.errors?.pattern">
        E-mail inválido!
      </p>
    </ng-container>
  </div>

  <div class="form-control-group">
    <span class="label-with-link">
      <label class="label" for="input-password">Senha:</label>
      <a class="forgot-password caption-2" routerLink="../request-password">Esqueceu sua senha?</a>
    </span>

    <nb-form-field>
      <input nbInput fullWidth [(ngModel)]="user.password" #password="ngModel" name="password"
      [type]="(this.showPassword) ? 'text' : 'password'" autofocus
      id="input-password" placeholder="Senha" fieldSize="large"
      [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : 'basic'" [required]="true"
      [attr.aria-invalid]="password.invalid && password.touched ? true : null">

      <button nbSuffix nbButton ghost (mousedown)="this.showPassword = !this.showPassword" (mouseup)="this.showPassword = !this.showPassword" type="button"
        (touchstart)="this.showPassword = !this.showPassword"  (touchend)="this.showPassword = !this.showPassword">
        <nb-icon [icon]="showPassword ? 'eye-outline' : 'eye-off-2-outline'" pack="eva"
          [attr.aria-label]="showPassword ? 'hide password' : 'show password'">
        </nb-icon>
      </button>
    </nb-form-field>
    <ng-container *ngIf="password.invalid && password.touched ">
      <p class="caption status-danger" *ngIf="password.errors?.required">
        Senha é obrigatória!
      </p>
    </ng-container>
  </div>

  <button nbButton fullWidth status="primary" size="large" [disabled]="submitted || !form.valid" id="button-entrar" type="submit"
    [class.btn-pulse]="submitted">
    Entrar
  </button>
</form>
