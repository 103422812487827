import { Observable } from "rxjs";
import { BaseResponse, BaseResponsePagination } from "./bases/base-response";

export interface CostCenter{
    id?:number;
    name?:string;
    abbreviation?:string;
    start_date?:string;
    end_date?:string;
    situation?:string;
}
export enum CostCenterEnum {
  A = "Ativa",
  I = "Inativa",
}

export interface CostCenterFilter{
  id?:number;
  name?:string;
  abbreviation?:string;
  start_date?:Date;
  end_date?:Date;
  situation?:string;
}
export abstract class CostCenterData {
abstract list(filter:CostCenterFilter, pagination:any): Observable<BaseResponse<CostCenter[],BaseResponsePagination>>
abstract delete(id);
abstract get(id);
abstract getCostCenter();
abstract insert(costCenter:CostCenter);
abstract update(costCenter:CostCenter)
}