<nb-card>
    <nb-card-header>Adicionar nova faixa de valor</nb-card-header>
    <nb-card-body>
      <form #faixaForm="ngForm">
        <div class="form-row">
          <div class="col-md-2">
            <label for="quantasFaixas">Quantas faixas quer adicionar?</label>
            <input nbInput [(ngModel)]="quantasFaixas" name="quantasFaixas" required>
          </div>
          <div *ngFor="let faixa of faixa; let i = index" class="col-md-2">
            <label for="inicioVigencia">Início da Vigência</label>
            <input nbInput [(ngModel)]="faixa.start_of_validity" name="start_of_validity{{i}}" required>
          </div>
          <div *ngFor="let faixa of faixa; let i = index" class="col-md-2">
            <label for="fimVigencia">Fim da Vigência</label>
            <input nbInput [(ngModel)]="faixa.end_of_validity" name="end_of_validity{{i}}" required>
          </div>
          <div *ngFor="let faixa of faixa; let i = index" class="col-md-2">
            <label for="inicioFaixaValor">Início faixa de valor</label>
            <input nbInput [(ngModel)]="faixa.start_price_range" name="start_price_range{{i}}" required>
          </div>
          <div *ngFor="let faixa of faixa; let i = index" class="col-md-2">
            <label for="fimFaixaValor">Fim faixa de valor</label>
            <input nbInput [(ngModel)]="faixa.end_price_range" name="end_price_range{{i}}" required>
          </div>
          <div *ngFor="let faixa of faixa; let i = index" class="col-md-2">
            <label for="aliquota">Respectiva alíquota</label>
            <input nbInput [(ngModel)]="faixa.tax_rate" name="tax_rate{{i}}" required>
          </div>
          <div *ngFor="let faixa of faixa; let i = index" class="col-md-2">
            <label for="parcelaDeduzir">Parcela a deduzir</label>
            <input nbInput [(ngModel)]="faixa.deductible_amount" name="deductible_amount{{i}}" required>
          </div>
        </div>
      </form>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="success" (click)="confirmarFaixas()">Confirmar</button>
      <button nbButton status="danger" (click)="cancelar()">Cancelar</button>
    </nb-card-footer>
  </nb-card>
  