import { ChangeDetectorRef, Component, ElementRef, Inject, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { NB_AUTH_OPTIONS, NbAuthJWTToken, NbAuthResult, NbAuthService } from '@nebular/auth';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { merge } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { LocalStorageData } from '../../@core/data/local-storage';
import { Account } from '../../@core/data/account';
import { CommonData } from '../../@core/data/common';
import { environment } from '../../../environments/environment';
import { Bank } from '../../@core/data/bank';
import { BaseResponse } from '../../@core/data/bases/base-response';
import { Menu, MenuData } from '../../@core/data/menu';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  redirectDelay: number = 0;
  showMessages: any = {};
  strategy: string = '';
  errors: string[] = [];
  messages: string[] = [];
  user: any = {};
  submitted: boolean = false;
  showPassword = false;
  scriptElement: HTMLScriptElement;
  errorMenuIsEmpty: boolean = false

  constructor(protected service: NbAuthService,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected cd: ChangeDetectorRef,
    protected router: Router,
    private _el: ElementRef,
    private _renderer: Renderer2,
    private spinner: NgxSpinnerService,
    private localStorage: LocalStorageData,
    private commonData: CommonData,
    private menuService: MenuData
  ) {
    this.strategy = 'email'
    this.showMessages = {
      success: true,
      error: true,
    }

    this.scriptElement = document.createElement("script")
    this.scriptElement.innerHTML = "document.getElementById(\"input-email\").addEventListener(\"focusout\", () => {document.getElementById(\"input-password\").focus()});"
    const original = this._el.nativeElement as HTMLElement;
    this._renderer.appendChild(original, this.scriptElement)
  }

  login(): void {
    this.errors = [];
    this.messages = [];
    this.submitted = true;
    this.localStorage.remove('menu')

    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          this.spinner.show()
          return this.service.authenticate(this.strategy, this.user)
        }),
        map(data => {
          return data;
        }),
        catchError(err => {
          return err;
        })
      )
      .subscribe((result: NbAuthResult) => {
        if (result.isSuccess()) {
          this.service.onTokenChange()
            .subscribe((token: NbAuthJWTToken) => {
              if (token.isValid()) {
                this.getVersion()
                this.getMenu()
              }
            });
        } else {
          var response = result.getResponse() as BaseResponse<any, any>
          if (response.statusCode == 400)
            this.errors = ["Login/senha informados estão incorretos"]

          this.submitted = false
          this.spinner.hide()
        }

        this.cd.detectChanges();
      });
  }

  getVersion() {
    this.commonData.getVersion().subscribe((result) => {
      const production = environment.production
      const version = result.data.find(v => v.environment === ((production) ? "production" : "homolog"))
      this.localStorage.set('version', version)
    })
  }

  getMenu() {
    this.errorMenuIsEmpty = false

    this.menuService.getByUser()
      .subscribe((response: Menu[]) => {
        if (response.length > 0) {
          this.localStorage.set('menu', response)
          var redirect: string = "/pages/home"
          this.router.navigateByUrl(redirect);  
        } else {
          this.logout()
          this.errorMenuIsEmpty = true
          this.spinner.hide()
        }
      },
        (result) => {
          this.spinner.hide()
        })
  }
  
  logout(): void {
    this.localStorage.remove('auth_app_token')
  }
}
