import { Observable } from "rxjs";
import { BaseResponse, BaseResponsePagination } from "./bases/base-response";

export interface Demonstrative{
    id?:number;
    name?:string;
    acronym?:string;
    start_date?:string;
    end_date?:string;
    situation?:string;
    observation?:string;
}

export interface DemonstrativeFilter{
  id?:number;
  name?:string;
  acronym?:string;
  start_date?:Date;
  end_date?:Date;
  situation?:string;
  observation?:string;
}
export abstract class DemonstrativeData {
abstract list(filter:DemonstrativeFilter, pagination:any): Observable<BaseResponse<Demonstrative[],BaseResponsePagination>>
abstract delete(id);
abstract get(id);
abstract insert(costCenter:Demonstrative);
abstract update(costCenter:Demonstrative)
}
 