import { Observable } from "rxjs";

import { BaseFilter } from "./bases/base-filter";
import { BaseResponse, BaseResponsePagination } from "./bases/base-response";
import { ContactRequestDto, ContactResponseDto } from "./dto/contact.dto";

export interface JuridicContactFilter extends BaseFilter {
  id?: string;
}

export abstract class JuridicContactData {
  abstract list(
    juridicId: string,
    filter: JuridicContactFilter
  ): Observable<BaseResponse<ContactResponseDto[], BaseResponsePagination>>;
  abstract getById(
    id: string
  ): Observable<BaseResponse<ContactResponseDto, BaseResponsePagination>>;
  abstract update(id: string, request: ContactRequestDto): Observable<any>;
  abstract insert(
    juridicId: string,
    request: ContactRequestDto
  ): Observable<any>;
  abstract delete(id: string): Observable<any>;
}
