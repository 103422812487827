import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import {
  BaseResponse,
  BaseResponsePagination,
} from "../data/bases/base-response";
import {
  JuridicType,
  JuridicTypeData,
  JuridicTypeFilter,
} from "../data/juridic_type";
import { setBaseFilter } from "../utils/http/base-http-filter";

@Injectable()
export class JuridicTypeService extends JuridicTypeData {
  private baseUrl = environment.services.accessControl.baseUrl;

  constructor(private http: HttpClient) {
    super();
  }

  list(
    filter: JuridicTypeFilter
  ): Observable<BaseResponse<JuridicType[], BaseResponsePagination>> {
    var params = setBaseFilter(filter);

    if (filter.id) params = params.set("id", filter.id);

    if (filter.name) params = params.set("name", filter.name);

    if (filter.isActive) params = params.set("isActive", filter.isActive);

    return this.http.get<BaseResponse<JuridicType[], BaseResponsePagination>>(
      `${this.baseUrl}/api/v1/juridic_type/`,
      { params }
    );
  }
}
