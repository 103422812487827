import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAuthJWTToken, NbAuthModule, NbPasswordAuthStrategy } from '@nebular/auth';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { of as observableOf } from 'rxjs';
import { MockDataModule } from './mock/mock-data.module';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { environment } from '../../environments/environment';
import { AuthModule } from '../auth/auth.module';
import { AuthGuard } from '../auth/auth-guard.service';

//Datas
import { UserData } from './data/user';
import { AccountData } from './data/account';
import { ContextData } from './data/context';
import { CreditSummaryData } from './data/credit-summary';
import { DailyConsolidationData } from './data/daily-consolidation';
import { LocalStorageData } from './data/local-storage';
import { UserAccountData } from './data/user-account';
import { TransactionData } from './data/transaction';
import { BankData } from './data/bank';
import { CommonData } from './data/common';
import { UserGroupData } from './data/user-group';
import { ParamData } from './data/params';
import { ModuleData } from './data/module';
import { FuncionalityData } from './data/funcionality';
import { ClientData } from './data/client';
import { AuthData } from './data/auth';
import { MenuData } from './data/menu';
import { CostCenterData } from './data/cost-center';
import { AssetReductionData } from './data/asset-reduction';
import { DemonstrativeData } from './data/demonstrative';
import { NatureCountData } from './data/nature-count';
import { ProvisionCountData } from './data/provision';
import { TaxData } from './data/tax';

//Services
import { UserService } from './service/user.service';
import { AccountService } from './service/account.service';
import { LocalStorageService } from './service/local-storage.service';
import { ContextService } from './service/context.service';
import { UserAccountService } from './service/user-account.service';
import { TransactionService } from './service/transaction.service';
import { DailyConsolidationService } from './service/daily-consolidation.service';
import { CreditSummaryService } from './service/credit-summary.service';
import { BankService } from './service/bank.service';
import { CommonService } from './service/common.service';
import { UserGroupService } from './service/user-group.service';
import { ParamService } from './service/param.service';
import { ModuleService } from './service/module.service';
import { FuncionalityService } from './service/funcionality.service';
import { MenuService } from './service/menu.service';
import { AuthService } from './service/auth.service';
import { ClientService } from './service/client.service';
import { DemonstrativeService } from './service/demonstrative.service';
import { CostCenterService } from './service/cost-center.service';
import { NatureCountService } from './service/nature-count.service';
import { ProvisionCountService } from './service/provision.service';
import { AssetReductionService } from './service/asset-reduction.service';
import { TaxService } from './service/tax.service';


const DATA_SERVICES = [
  { provide: LocalStorageData, useClass: LocalStorageService },
  { provide: UserData, useClass: UserService },
  { provide: AccountData, useClass: AccountService },
  { provide: BankData, useClass: BankService },
  { provide: ContextData, useClass: ContextService },
  { provide: UserAccountData, useClass: UserAccountService },
  { provide: TransactionData, useClass: TransactionService },
  { provide: DailyConsolidationData, useClass: DailyConsolidationService },
  { provide: CreditSummaryData, useClass: CreditSummaryService },
  { provide: ParamData, useClass: ParamService },
  { provide: CommonData, useClass: CommonService },
  { provide: UserGroupData, useClass: UserGroupService },
  { provide: ModuleData, useClass: ModuleService },
  { provide: FuncionalityData, useClass: FuncionalityService },
  { provide: MenuData, useClass: MenuService },
  { provide: AuthData, useClass: AuthService },
  { provide: ClientData, useClass: ClientService },
  { provide: DemonstrativeData, useClass: DemonstrativeService }, 
  { provide: CostCenterData, useClass: CostCenterService },
  { provide: NatureCountData, useClass: NatureCountService },
  { provide: ProvisionCountData, useClass: ProvisionCountService },
  { provide: AssetReductionData, useClass: AssetReductionService },
  { provide: TaxData, useClass: TaxService },
];

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf('guest');
  }
}

export const NB_CORE_PROVIDERS = [
  AuthGuard,
  ...MockDataModule.forRoot().providers,
  ...DATA_SERVICES,
  ...NbAuthModule.forRoot({

    strategies: [
      NbPasswordAuthStrategy.setup({
        name: 'email',

        token: {
          class: NbAuthJWTToken,
        },

        baseEndpoint: environment.services.accessControl.baseUrl,
        // baseEndpoint: ' http://127.0.0.1:5000',
        login: {
          endpoint: '/api/v1/authentication/login'
        },
        requestPass: {
          redirect: {
            success: '/auth/reset-password',
          },
        },
        resetPass: {
          redirect: {
            success: '/auth/login',
          },
        },
        errors: {
          key: 'data.errors',
        },
      }),
    ],
    forms: {
      login: {
        strategy: 'email',
        redirectDelay: 1000,
        showMessages: {
          success: true,
          error: true,
        },
      },
    },
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: '*',
      },
      user: {
        parent: 'guest',
        create: '*',
        edit: '*',
        remove: '*',
      },
    },
  }).providers,

  {
    provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
  }
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    NbAuthModule,
    AuthModule
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
