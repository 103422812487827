import { Observable } from "rxjs";

import { BaseFilter } from "./bases/base-filter";
import { BaseResponse, BaseResponsePagination } from "./bases/base-response";
import { AccountRequestDto, AccountResponseDto } from "./dto/account.dto";

export interface JuridicAccountFilter extends BaseFilter {
  id?: string;
}

export abstract class JuridicAccountData {
  abstract list(
    juridicId: string,
    filter: JuridicAccountFilter
  ): Observable<BaseResponse<AccountResponseDto[], BaseResponsePagination>>;
  abstract getById(
    id: string
  ): Observable<BaseResponse<AccountResponseDto, BaseResponsePagination>>;
  abstract update(id: string, request: AccountRequestDto): Observable<any>;
  abstract insert(
    juridicId: string,
    request: AccountRequestDto
  ): Observable<any>;
  abstract delete(id: string): Observable<any>;
}
