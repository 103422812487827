import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseResponse, BaseResponsePagination } from '../data/bases/base-response';
import { toBackendDateFormat } from '../utils/common/datetime-utils';
import { Demonstrative, DemonstrativeData, DemonstrativeFilter } from '../data/demonstrative';

@Injectable()
export class DemonstrativeService extends DemonstrativeData {

  private baseUrl = environment.services.financial.baseUrl;

  constructor(private http: HttpClient) { super() }

  list(filter: DemonstrativeFilter, pagination: any): Observable<BaseResponse<Demonstrative[], BaseResponsePagination>> {
    let params = new HttpParams()
      .set('page', pagination.currentPage)
      .set('page_size', pagination.perPage);

    // Adiciona as datas apenas se elas estiverem definidas
    if (filter.start_date instanceof Date) {
      params = params.set('start_date', toBackendDateFormat(filter.start_date));
    }
    if (filter.end_date instanceof Date) {
      params = params.set('end_date', toBackendDateFormat(filter.end_date));
    }

    if (filter.acronym) params = params.set('acronym', filter.acronym);
    if (filter.name) params = params.set('name', filter.name);
    if (filter.situation) params = params.set('situation', filter.situation);

    params = params.set('orderBy', '').set('sortedBy', '');

    return this.http.get<BaseResponse<Demonstrative[], BaseResponsePagination>>(
      `${this.baseUrl}/api/demonstrative/`, 
      { params }
    );
  }
  
  getDemonstrative(){
    return this.http.get(`${this.baseUrl}/api/demonstrative/`)
  }
  
  delete(id){
    return this.http.delete(`${this.baseUrl}/api/demonstrative/${id}`,id)
  }

  update(costCenter:Demonstrative){
    const { id, ...costCenterData } = costCenter;
    return this.http.put(`${this.baseUrl}/api/demonstrative/${costCenter.id}/`, costCenterData)
  }

  insert(costCenter:Demonstrative){
    return this.http.post(`${this.baseUrl}/api/demonstrative/`,costCenter)
  }

  get(id) {
    var result = this.http.get(`${this.baseUrl}/api/demonstrative/${id}/`,id)
    return result;
  }


}