import { Observable } from "rxjs";
import { BaseResponse, BaseResponsePagination } from "./bases/base-response";


export interface NatureCount {
  id?:number;
  name?:string;
  
}

export interface NatureCountFilter{
  id?:number;
  
}

export abstract class NatureCountData {
    abstract list(filter:NatureCount, pagination:any): Observable<BaseResponse<NatureCount[],BaseResponsePagination>>
    abstract insert(natureCount:NatureCount);
    abstract get(id);
    abstract delete(id);
    abstract update(natureCount:NatureCount)
    
}
