import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { BaseResponse, BaseResponsePagination } from "../data/bases/base-response";
import { AssetReductionCount, AssetReductionData } from "../data/asset-reduction";

@Injectable()
export class AssetReductionService extends AssetReductionData {
    private baseUrl = environment.services.financial.baseUrl;

  constructor(private http:HttpClient) { super() }

  list(filter:AssetReductionCount, pagination:any): Observable<BaseResponse<AssetReductionCount[],BaseResponsePagination>> {
    var params = new HttpParams()
    params = params.set('currentPage', pagination.currentPage)
    params = params.set('pageSize', pagination.pageSize)

    if(filter.id)
      params = params.set('id', filter.id)

    if(filter.name)
      params = params.set('name', filter.name)

    params = params.set('orderBy','')
    params = params.set('sortedBy','')

    return this.http.get<BaseResponse<AssetReductionCount[],BaseResponsePagination>>(`${this.baseUrl}/api/asset_reduction/`, { params })
  }

  insert(assetReductionCount:AssetReductionCount){
    return this.http.post(`${this.baseUrl}/api/asset_reduction/`,assetReductionCount)
  }

  delete(id){
    return this.http.delete(`${this.baseUrl}/api/asset_reduction/${id}`,id)
  }

  update(assetReductionCount:AssetReductionCount){
    const assetReductionCountData = { "name": assetReductionCount.name };
    return this.http.put(`${this.baseUrl}/api/asset_reduction/${assetReductionCount.id}/`,assetReductionCountData)
  }

  get(id) {
    var result = this.http.get(`${this.baseUrl}/api/asset_reduction/${id}/`,id)
    return result;
  }
}
