import { Observable } from "rxjs";

import { PersonType } from "../utils/enum/person-type.enum";
import { BaseFilter } from "./bases/base-filter";
import { BaseModel } from "./bases/base-model";
import { BaseResponse, BaseResponsePagination } from "./bases/base-response";
import { Profiles } from "./profiles";

export interface Document extends BaseModel {
  id?: string;
  name?: string;
  required?: boolean;
  hasDueDate?: boolean;
  link?: string;
  personType?: PersonType;
  profiles?: Profiles[];
}

export interface DocumentFilter extends Document, BaseFilter {}

export abstract class DocumentData {
  abstract list(
    filter: DocumentFilter
  ): Observable<BaseResponse<Document[], BaseResponsePagination>>;
  abstract insert(document: Document);
  abstract delete(id);
  abstract update(document: Document);
}
