import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import {
  AccountType,
  AccountTypeData,
  AccountTypeFilter,
} from "../data/account-type";
import {
  BaseResponse,
  BaseResponsePagination,
} from "../data/bases/base-response";
import { setBaseFilter } from "../utils/http/base-http-filter";

@Injectable()
export class AccountTypeService extends AccountTypeData {
  private baseUrl = environment.services.accessControl.baseUrl;

  constructor(private http: HttpClient) {
    super();
  }

  list(
    filter: AccountTypeFilter
  ): Observable<BaseResponse<AccountType[], BaseResponsePagination>> {
    var params = setBaseFilter(filter);

    if (filter.id) params = params.set("id", filter.id);
    if (filter.name) params = params.set("name", filter.name);

    return this.http.get<BaseResponse<AccountType[], BaseResponsePagination>>(
      `${this.baseUrl}/api/v1/account_type`,
      { params }
    );
  }

  update(accountType: AccountType) {
    return this.http.put(
      `${this.baseUrl}/api/v1/account_type/${accountType.id}`,
      accountType
    );
  }

  delete(id) {
    return this.http.delete(`${this.baseUrl}/api/v1/account_type/${id}`, id);
  }

  insert(accountType: AccountType) {
    return this.http.post(`${this.baseUrl}/api/v1/account_type/`, accountType);
  }
}
